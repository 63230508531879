import { graphql, Link } from "gatsby";
import React, { Fragment } from "react";
import {
  PersonTimelineQuery,
  YearSelectionFragment,
} from "../../graphql-schema-types";
import {
  AwardCategoryHeading,
  MainCategoryHeading,
} from "../components/Atoms/CategoryHeading";
import { Heading1, Heading3 } from "../components/Atoms/Text";
import {
  EntryPresentation,
  HostSpeakerAndJury,
} from "../components/Organisms/SharedAwardPresentation";
import { extractYearData } from "../formatModels";
import { css } from "@emotion/core";
import { verticalMargin } from "../margins";
import { bpLarge, bpMedium } from "../stylingConstants";
import Seo from "../components/Seo";
import { getYearRoute } from "../routeHelper";
import leftArrow from "../assets/leftArrow.svg";

type Props = {
  data: PersonTimelineQuery;
};

type YearData = YearSelectionFragment;

export default function PersonTimeline({ data }: Props) {
  const { name, image } = data.allContentfulPerson.nodes[0];
  const years = extractQueryData(data);
  const NameHeading = Heading3.withComponent("h1");
  return (
    <>
      <Seo title={name} image={image?.fluid?.src} />
      <NameHeading>{name}</NameHeading>
      <hr
        css={css`
        border-top: 1px solid #dedede;
        border-bottom: none;
        ${verticalMargin(25)}
        @media ${bpLarge}{
          ${verticalMargin(35)}
        }
      `}
      />
      {years?.map((year) => (
        <Fragment key={year.year}>
          <Link to={getYearRoute(year.year)}>
            <MainCategoryHeading
              css={css`
                ::after {
                  content: url(${leftArrow});
                  display: inline-block;
                  margin-left: 20px;
                  position: relative;
                  top: 0px;
                  transform: rotate(180deg);
                  margin-left: 10px;
                  @media ${bpMedium} {
                    transform: scale(1.2) rotate(180deg);
                    margin-left: 15px;
                    top: 2px;
                  }
                  @media ${bpLarge} {
                    transform: scale(1.5) rotate(180deg);
                    margin-left: 20px;
                    top: 11px;
                  }
                }
              `}
            >
              {year.year}
            </MainCategoryHeading>
          </Link>
          {year.awards.map((award) => (
            <Fragment key={award.title}>
              <AwardCategoryHeading>{award.title}</AwardCategoryHeading>
              {award.winner && (
                <EntryPresentation type="winner" data={award.winner} />
              )}
              {award.nominees.map((nominee) => (
                <EntryPresentation
                  type="nominated"
                  data={nominee}
                  key={nominee.title}
                />
              ))}
            </Fragment>
          ))}
          <HostSpeakerAndJury
            year={year}
            deemphasizedHeadings
            juryRowCount={5}
          />
        </Fragment>
      ))}
    </>
  );
}

//This is a highly complicated way to get the data back from the graphql yes.
function extractQueryData(data: PersonTimelineQuery) {
  if (data == null) {
    return;
  }
  const years = new Map<string, YearData>();
  const addYearData = (year: string, data: YearData) => {
    years.set(year, { ...(years.get(year) ?? { year, awards: [] }), ...data });
  };
  data.allContentfulPerson.nodes.forEach((person) => {
    person.year?.forEach((year) => {
      if (year?.jury?.find((juror) => juror?.slug === person.slug)) {
        addYearData(year.year, { jury: year.jury });
      }
      const speaker = year?.speakers?.find(
        (speaker) => speaker?.slug === person.slug
      );
      if (year && speaker != null) {
        addYearData(year.year, { speakers: [speaker] });
      }
      if (year && year?.host?.slug === person.slug) {
        addYearData(year.year, { host: year.host });
      }
    });
    person.work?.forEach((work) => {
      const award = work?.award?.[0];
      const year = award?.year?.[0]?.year;
      if (!work || !award || !year) {
        return;
      }
      if (award.winningWork?.id === work.id) {
        addYearData(year, {
          awards: [
            ...(years.get(year)?.awards ?? []),
            { title: award.title, winningWork: work },
          ],
        });
      } else if (
        award.nominatedWorks?.find((nominee) => nominee?.id === work.id)
      ) {
        addYearData(year, {
          ...(years.get(year)?.awards ?? []),
          awards: [{ title: award.title, nominatedWorks: [work] }],
        });
      }
    });
  });
  return Array.from(years.values())
    .map(extractYearData)
    .sort((year1, year2) => year2.year - year1.year);
}

export const query = graphql`
  query PersonTimeline($personSlug: String) {
    allContentfulPerson(filter: { slug: { eq: $personSlug } }) {
      nodes {
        name
        image {
          fluid(maxWidth: 300) {
            src
          }
        }
        slug
        year {
          year
          jury {
            ...PersonSelection
          }
          host {
            ...PersonSelection
          }
          speakers {
            ...PersonSelection
          }
        }
        work {
          ...WorkSelection
          award {
            title
            year {
              year
            }
            winningWork {
              id
            }
            nominatedWorks {
              id
            }
          }
        }
      }
    }
  }
`;
